import * as React from "react";

import "./VerticalMenu.css";

interface VerticalMenuProps {
    items: {
        Title: JSX.Element;
        Content: JSX.Element;
        SubTitle: JSX.Element;
    }[];
}

const VerticalMenu: React.FC<VerticalMenuProps> = ({ items }) => {
    const [openedIndex, setOpenedIndex] = React.useState(-1);

    return (
        <div className="verticalMenuContainer">
            {
                items.map(({ Title, Content, SubTitle }, index) => {
                    const titleRef = React.useRef<HTMLDivElement>(null);
                    const contentRef = React.useRef<HTMLDivElement>(null);
                    const isOpened = openedIndex === index;
                    
                    const toggleItem = React.useCallback(() => {
                        const toOpen = openedIndex !== index;

                        if (toOpen) {
                            if (contentRef.current) {
                                contentRef.current.style.paddingBottom = `${contentRef.current.scrollHeight}px`;
                            }
                            setOpenedIndex(index);
                        }
                    }, [openedIndex]);

                    return (
                        <div className={`verticalMenuItem ${isOpened ? "opened" : ""}`} key={`verticalMenu-item-${index}`}>
                            <div className={`verticalMenuTitleCard`} onClick={toggleItem} ref={titleRef}>
                                <div className={`verticalMenuTitle ${isOpened ? "" : "visible"}`}>
                                    {Title}
                                </div>
                                <div className="verticalMenuSubTitle">
                                    {SubTitle}
                                </div>
                                <div
                                    className={`verticalMenuContent ${isOpened ? "visible" : ""}`}
                                    ref={contentRef}
                                    style={{ paddingBottom: isOpened && contentRef.current ? `${contentRef.current.scrollHeight}px` : 0 }}
                                >
                                    {Content}
                                </div>
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

export default VerticalMenu;
