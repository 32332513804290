import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Slider, { Settings } from "react-slick";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import { PageId } from "../components/Navigation";
import { COLORS } from "../utils/consts";
import Accordion from "../components/Accordion";
import VerticalMenu from "../components/VerticalMenu";
import BottomCommonSection from "../components/BottomCommonSection";
import Arrow from "../svgComponents/Arrow";
import { getIsVertical } from "../utils/getIsVertical";

import "../styles/veselibas-centrs-liepaja.css";

const sliderSettings: Settings = {
    arrows: false,
    accessibility: false,
    dots: true,
};

export default function HealthCenterLiepaja() {
	const { t } = useTranslation();
	const isVertical = getIsVertical();
	const sliderRef = React.useRef<Slider>(null);

	const handleCarouselArrowsClick = React.useCallback((isRight: boolean) => {
		if (sliderRef.current) {
			sliderRef.current[isRight ? "slickNext" : "slickPrev"]();
		}
	}, []);

	return (
		<Layout pageId={PageId.HealthCenterLiepaja}>
			<Seo
                title={t("seoTitle")}
                description={t("seoDescription")}
            />
			<div className="contentBlock topTextSection">
				<h1 className="topText">
                    {t("whatsSocialCareCenter")}
				</h1>
			</div>
            <div className="contentBlock noPadding" style={{ display: "flex" }}>
                <iframe
                    src="https://www.facebook.com/plugins/video.php?height=314&href=https%3A%2F%2Fwww.facebook.com%2FRure.lv%2Fvideos%2F254396180549112%2F&show_text=false&width=560&t=0"
                    style={{ border: "none", overflow: "hidden", marginBottom: "-0.1rem", flexGrow: 1, aspectRatio: "16 / 9" }}
                    allowFullScreen={true}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    loading="lazy"
                ></iframe>
            </div>
            <div className="contentBlock noPadding">
                <table className="everydayTable">
                    <thead>
                        <tr>
                            <td colSpan={3} style={isVertical ? { fontSize: "3rem", padding: "5rem", borderBottom: `1px solid ${ COLORS.lightGreen }` } : {}}>
                                {t("RureEveryday")}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p><Trans i18nKey="environment" components={{ break: <br /> }} /></p>
                                <p>{t("environmentDescription")}</p>
                            </td>
                            <td>
                                <p><Trans i18nKey={"socMedSupport"} components={{ break: <br /> }} /></p>
                                <p>{t("socMedSupportDescription")}</p>
                            </td>
                            <td>
                                <p><Trans i18nKey={"everydayActivities"} components={{ break: <br /> }} /></p>
                                <p>{t("everydayActivitiesDescription")}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="contentBlock noPadding" style={{ height: isVertical ? "60rem" : "" }}>
				<StaticImage
					src="../images/veselibas-centrs-liepaja/1.png"
					quality={95}
					formats={["auto", "webp", "avif"]}
					alt="Veselības centrs Liepājā"
					placeholder="blurred"
                    style={{ height: "100%" }}
				/>
                {isVertical ? null : <div style={{ borderTop: `1px solid ${COLORS.lightGreen}`, textAlign: "center", padding: "0.8rem 0", fontStyle: "italic" }}>{t("centerAddress")}</div>}
            </div>
            <div className="contentBlock priceSection">
                <div className="priceDescription">
                    <p>{t("priceDescription1")}</p>
                    <p>{t("priceDescription2")}</p>
                </div>
                <div className="priceMenu">
                    <Accordion
                        items={[
                            {
                                Title: <h2 style={isVertical ? { fontSize: "6rem", whiteSpace: "nowrap", padding: "3rem 0 1rem" } : {}}>{t("1stLevel")}</h2>,
                                Content: (
                                    <div className="pricesContent">
                                        <p className="pricesDescription">{t("1stLevelDescription")}</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>29<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("tripleRoom")}</td>
                                                </tr>
                                                <tr>
                                                    <td>39<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("doubleRoom")}</td>
                                                </tr>
                                                <tr>
                                                    <td>44<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("singleRoom")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ),
                            },
                            {
                                Title: <h2 style={isVertical ? { fontSize: "6rem", whiteSpace: "nowrap", padding: "3rem 0 1rem" } : {}}>{t("2ndLevel")}</h2>,
                                Content: (
                                    <div className="pricesContent">
                                        <p className="pricesDescription">{t("2ndLevelDescription")}</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>35<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("tripleRoom")}</td>
                                                </tr>
                                                <tr>
                                                    <td>43,5<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("doubleRoom")}</td>
                                                </tr>
                                                <tr>
                                                    <td>49<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("singleRoom")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ),
                            },
                            {
                                Title: <h2 style={isVertical ? { fontSize: "6rem", whiteSpace: "nowrap", padding: "3rem 0 1rem" } : {}}>{t("3rdLevel")}</h2>,
                                Content: (
                                    <div className="pricesContent">
                                        <p className="pricesDescription">{t("3rdLevelDescription")}</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>40<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("tripleRoom")}</td>
                                                </tr>
                                                <tr>
                                                    <td>55<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("doubleRoom")}</td>
                                                </tr>
                                                <tr>
                                                    <td>70<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("singleRoom")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ),
                            },
                            {
                                Title: <h2 style={isVertical ? { fontSize: "6rem", whiteSpace: "nowrap", padding: "3rem 0 1rem" } : {}}>{t("4thLevel")}</h2>,
                                Content: (
                                    <div className="pricesContent">
                                        <p className="pricesDescription">{t("4thLevelDescription")}</p>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>49<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("tripleRoom")}</td>
                                                </tr>
                                                <tr>
                                                    <td>64<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("doubleRoom")}</td>
                                                </tr>
                                                <tr>
                                                    <td>75<span>€</span></td>
                                                    <td>{t("perDay")}</td>
                                                    <td>{t("singleRoom")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                ),
                            },
                        ]}
                    />
                </div>
            </div>
            <div className="contentBlock noPadding" style={{ borderBottom: "none" }}>
                <div className="rureImageCarousel">
                    <Slider {...sliderSettings} className="carousel" ref={sliderRef}>
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c1.png"
                            alt="Veselības centrs Liepājā (1)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c2.png"
                            alt="Veselības centrs Liepājā (2)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c3.png"
                            alt="Veselības centrs Liepājā (3)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c4.png"
                            alt="Veselības centrs Liepājā (4)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                        <StaticImage
                            src="../images/veselibas-centrs-liepaja/c5.png"
                            alt="Veselības centrs Liepājā (5)"
                            quality={95}
                            formats={["auto", "webp", "avif"]}
                            placeholder="blurred"
                        />
                    </Slider>
                    {
                        !isVertical && (
                            <div style={{
                                position: "absolute",
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: 0,
                                display: "flex",
                                justifyContent: "space-between",
                                pointerEvents: "none",
                            }}>
                                <div className="carouselArrowContainer" onClick={() => handleCarouselArrowsClick(false)}>
                                    <Arrow fill={COLORS.darkGreen} style={{ transform: "rotate(90deg)", height: "2rem" }} />
                                </div>
                                <div className="carouselArrowContainer" onClick={() => handleCarouselArrowsClick(true)}>
                                    <Arrow fill={COLORS.darkGreen} style={{ transform: "rotate(-90deg)", height: "2rem" }} />
                                </div>
                            </div>
                        )
                    }
                </div>
                <div style={{ borderTop: `1px solid ${COLORS.lightGreen}`, textAlign: "center", padding: "0.8rem 0", fontStyle: "italic" }}>{isVertical ? null : t("roomsDescription")}</div>
            </div>
            <div className="contentBlock">
                <div className="howToBecomeClient">
                    <div className="howToBecomeClientTitle"><Trans i18nKey={"howToBecomeClient"} components={{ break: <br /> }} /></div>
                    <div>
                        <p>{t("howToBecomeClient1")}</p>
                        <p>{t("howToBecomeClient2")}</p>
                    </div>
                </div>
            </div>
            <div className="contentBlock noPadding" style={isVertical ? { padding: "3rem" } : {}}>
                <VerticalMenu
                    items={[
                        {
                            Title: (
                                <div className="accordionBigNum">01</div>
                            ),
                            Content: (
                                <div>
                                    <p>{t("signUp1")}</p>
                                    <p>{t("signUp2")}</p>
                                </div>
                            ),
                            SubTitle: <p>{t("signUp")}</p>,
                        },
                        {
                            Title: (
                                <div className="accordionBigNum">02</div>
                            ),
                            Content: (
                                <div>
                                    <p>{t("firstVisit1")}</p>
                                    <p>{t("firstVisit2")}</p>
                                </div>
                            ),
                            SubTitle: <p>{t("firstVisit")}</p>,
                        },
                        {
                            Title: (
                                <div className="accordionBigNum">03</div>
                            ),
                            Content: (
                                <div>
                                    <p>{t("joining1")}</p>
                                    <p>{t("joining2")}</p>
                                </div>
                            ),
                            SubTitle: <p>{t("joining")}</p>,
                        },
                    ]}
                />
            </div>
            <div className="contentBlock noPadding" style={{ height: isVertical ? "60rem" : "45rem" }}>
				<StaticImage
					src="../images/veselibas-centrs-liepaja/2.png"
					quality={95}
					formats={["auto", "webp", "avif"]}
					alt="Veselības centrs Liepājā"
					placeholder="blurred"
                    style={{ height: "100%" }}
				/>
            </div>
            <BottomCommonSection />
		</Layout>
	);
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["veselibas-centrs-liepaja", "common", "BottomCommonSection"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
